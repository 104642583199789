.Clients{
    padding: 40px 64px;
    min-height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 32px;
    background-color:black;
    color: antiquewhite;
    position: relative;
}

.clientsHead{
    font-size: 40px;
    line-height: 1;
    font-weight: 600;
    position: absolute;
    top: 56px;
}

.projectCard{
    height: 23vw;
    position: relative;
    box-shadow: 0px 4px 8px 0px #00e1ff1a;
    border: 0.25px solid #00000018;
    margin: 32px 40px;
    /* margin-right: 40px; */
    position: relative;
}

.projectCard>img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top left;
}

.projName{
    z-index: 3;
    position: absolute;
    bottom: -16px;
    right: -16px;
    text-align: left;
    /* background-color: #0A0D0F; */
    background-color: antiquewhite;
    color: black;
    font-weight: 600;
    padding: 16px;
    box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.479);
    width: 160px;
    /* border: 0.2px solid #181B1F; */
    /* border-left: 0.25px solid rgba(255, 221, 176, 0.514);; */
}

.projecthover{
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000c9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hoverbtn{
    font-weight: 600;
    padding: 8px 12px;
    background-color: antiquewhite;
    color: black;
    border: 1px solid antiquewhite;
}
.hoverbtn:hover{
    font-weight: 600;
    padding: 8px 12px;
    background-color: black;
    color: antiquewhite;
    border: 1px solid antiquewhite;
}

.swiper{
    display: flex !important;
}

.swiper-button-prev::after, .swiper-button-next::after{
    font-size: 14px !important;
    font-weight: 600;
    color: black;
    background-color: antiquewhite;
    border-radius: 20px;
    height: 26px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px 0px #00000086;
    border: 0.75px solid #00e1ff1a;

}

@media only screen and (max-width:800px){
    .projectCard{
        /* height: 100%; */
        height: 250px;
    }
}

@media only screen and (orientation:portrait){
    .Clients{
        padding: 40px 0px;
    }
    
    .clientsHead{
        font-size: 32px;
    }
    
    .projName{
        padding: 8px 16px;
    }
}

@media only screen and (max-width:500px){
    .Clients{
        min-height: calc(80vh - 80px);
    }
    .projectCard{
        height: 200px;
        margin: 32px 32px;
    }
}