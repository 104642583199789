.Hero{
    padding: 64px 120px;
    min-height: calc(100vh - 128px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    background-image: url(../../assets/bg1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    text-align: left;
}

.subHeadHero{
    font-size: 24px;
    font-weight: 600;
    color: rgb(136, 136, 136);
    color: rgba(250, 235, 215, 0.63);
    margin-top: 32px;
}

.mainHeadHero{
    font-size: 48px;
    font-weight: 600;
    max-width: 600px;
}

.heroBtn{
    border: 1px solid white;
    color: white;
    margin-top: 32px;
}

@media only screen and (orientation: portrait){
    .Hero{
        padding: 64px 32px;
        min-height: calc(100vh - 128px);
        gap: 16px;
    }
    
    .subHeadHero{
        font-size: 20px;
        margin-top: 0px;
    }
    
    .mainHeadHero{
        font-size: 40px;
    }
}