@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  font-family: 'montserrat';
}

button{
  cursor: pointer;
  background-color: transparent;
  padding: 14px 32px;
  width: 200px;
  font-size: 16px;
  font-family: inherit;
  margin-top: 32px;
}

button:hover{
  background-color: #faebd7;
  color: black;
  font-weight: 500;
  border-color: black;
}

input{
  /* border-radius: 14px; */
  padding: 12px 16px;
  background-color: #6484961A;
  border: 0;
  border-bottom: 0.25px solid #2a2f336e;
  font-size: 14px;
  color: inherit;
  max-width: 320px;
  width: 80%;
}

a{
  text-decoration: none;
  color: inherit;
}

.dummyNav{
  background-color: black;
  border-radius: 45px;
  position: absolute;
  top: 16px;
  left: 24px;
  height: 64px;
  right: 24px;
  z-index: 4;
  box-shadow: 5px 5px 10px #ffffff15;
}

@media only screen and (orientation: portrait) {
  .dummyNav{
    left: 16px;
    right: 16px;
  }
}