.ProjectPage{
    min-height: 100vh;
    padding: 128px 64px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    text-align: left;
}

.proj_Section{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 1000px;
}
.proj_MainHead{
    font-size: 20px;
    max-width: 800px;
    font-weight: 600;
}
.proj_Head{
    max-width: 800px;
    font-weight: 600;
}

.proj_text{
    font-size: 14px;
    max-width: 800px;
}

.proj_Services{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 4px;
    font-size: 14px;
}

.proj_halfImg{
    width: calc(40.2%);
    /* height: 100%; */
    display: flex;
    justify-content: center;
    /* align-items:flex-start; */
    background-color: rgba(0, 0, 0, 0.39);
    background-color: antiquewhite;
    padding: 16px;
}
.proj_halfImg>img{
    object-fit: contain;
    width: 100%;
    /* max-width: 400px; */
}

.proj_fullImg{
    width: 85%;
    display: flex;
    justify-content: center;
    background-color: antiquewhite;
    padding: 16px;
}
.proj_fullImg>img{
    width: 100%;
    object-fit: contain;
    /* max-width: 400px; */
}

.section_divider{
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    margin-bottom: 20px;
}

.companyProfile, .companyLogos, .companyUI, .companyCatalogue, .companyMobile{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    gap: 16px;
    justify-content: center;
}

.companyCatalogue>div{
    width: 25.5%;
}

.companyInfoGr{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    gap: 16px;
    justify-content: center;
}
.companyInfoGr>div{
    width: 50%;
}

.proj_SubHead{
    font-size: 18px;
    display: flex;
    gap: 4px;
    font-weight: 600;
    margin-top: 16px;
}

.proj_numHead{
    font-size: 64px;
    font-weight: 700;
    line-height: 48px;
}

.proj_text.ongoing{
    font-size: 16px;
    font-weight: 800;
    font-style: italic;
    padding-left: 48px;
    /* color: white; */
}

.service_LargeHead{
    font-size: 56px;
    font-weight: 800;
    z-index: 1;
    color: white;
    /* text-shadow: 0px 0px 4px #000000; */
}


@media only screen and (orientation: portrait) {
    .ProjectPage{
        padding: 128px 24px 64px;
    }
    
    .proj_Services{
        grid-template-columns: repeat(2, 1fr) !important;
    }
    
    .proj_halfImg{
        width: 85% !important;
        padding: 8px !important;
    }
    
    .proj_fullImg{
        padding: 8px !important;
    }
    
    .companyCatalogue>div{
        width: 85% !important;
    }

    .companyInfoGr>div{
        width: 85% !important;
    }
    
    .service_LargeHead{
        font-size: 32px;
    }
}