.AboutPage {
    min-height: 100vh;
    padding: 0px 64px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    text-align: left;
}

.about_LargeHead {
    font-size: 40px;
    font-weight: 800;
    margin-top: -170px;
    z-index: 1;
    color: white;
    text-shadow: 0px 0px 4px #000000;
}

@media only screen and (orientation: portrait) {
    .AboutPage {
        padding: 0px 24px 64px;
    }

    .about_LargeHead {
        font-size: 24px;
        font-weight: 800;
        margin-top: -150px;
        z-index: 1;
        color: white;
        text-shadow: 0px 0px 4px #000000;
    }
}