.Services{
    padding: 64px 80px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 48px;
    background-color: black;
    color: white;
}

.servicesHead{
    font-size: 40px;
    font-weight: 400;
}

.ServicesContainer{
    display: flex;
    justify-content: center;
    gap: 64px;
    flex-wrap: wrap;
}

.serviceBox{
    background-color: black;
    /* padding: 4px; */
    border-bottom: 1px solid transparent;
    cursor: pointer;
    color: antiquewhite;
}

.serviceBox:hover{
    border-bottom: 1px solid antiquewhite;
}

.serviceImgDiv{
    height: 260px;
    width: 420px;
}

.serviceImgDiv>img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.serviceContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
}

.serviceName{
    font-weight: 500;
    text-align: left;
}

.serviceBtn{
    margin-top: 0;
    font-size: 14px;
    padding: 12px 16px;
    width: 120px;
    border: 1px solid antiquewhite;
    color: antiquewhite;
    background-color: black;
}

.viewMoreBtn{
    border: 1px solid antiquewhite;
    color: antiquewhite;
    align-self: center;
    width: 230px;
}

.more_service_container{
    max-width: 900px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    gap: 2%;
    /* padding-left: 40px; */
    /* padding-right: 40px; */
}

.headings{
    min-width: 220px;
    /* background-color: aqua; */
}

.service_heading{
    padding: 16px 16px;
    background-color: antiquewhite;
    color: black;
    font-weight: 700;
    margin: 16px 0;
    cursor: pointer;
    text-align: left;
    transition: 200ms ease-in-out;
    min-height: 22px;
}

.service_heading:hover, .service_heading.selected{
    background-color: white;
    color: black;
    font-size: 17px;
}

.content{
    flex-grow: 1; 
    /* background-color: rgb(0, 87, 87); */
    border-left: 1px solid antiquewhite;
    padding: 32px 16px;
}

.content>div{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.service{
    color: white;
    background-color: transparent;
    border: 1px solid antiquewhite;
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
}
.service:hover{
    color: black;
    background-color: antiquewhite;
}


@media only screen and (orientation: portrait) {
    .Services{
        padding: 64px 24px;
    }
    
    .servicesHead{
        font-size: 32px;
    }
    
    .serviceBox{
        width: 100%;
    }
    
    .serviceImgDiv{
        width: 100%;
    }

    .more_service_container{
        flex-direction: column;
    }
    
    .headings{
        min-width: 220px;
    }
    
    .service_heading{
        padding: 10px 12px;
        font-size: 14px;
    }
    
    .service_heading:hover, .service_heading.selected{
        font-size: 15px;
    }
    
    .content{
        padding: 24px 16px;
    }
    
    .content>div{
        gap: 12px;
    }
    
    .service{
        padding: 8px 10px;
        font-size: 12px;
    }
}