.Nav{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 96px;
    padding: 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    z-index: 5;
}

.navItems{
    display: flex;
    gap: 32px;
    font-weight: 500;
}

.navlogo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo{
    height: 36px;
}

.nav_btn{
    cursor: pointer;
    padding: 0px;
    width: 64px;
    font-size: inherit;
    font-family: inherit;
    border: none !important;
    color: antiquewhite;
    margin-top: 0px;
  }
  
  .nav_btn:hover{
      background-color: transparent;
      color: antiquewhite;
      font-weight: 600;
    }

@media only screen and (orientation: portrait){
    .Nav{
        padding: 0 24px;
    }
    
    .navItems{
        display: flex;
        gap: 8px;
        font-size: 12px;
        margin-right: 8px;
    }

    .logo{
        height: 32px;
    }

    .nav_btn{
        width: 48px;
    }
}